import { lazy, Suspense } from 'react';

const LoginSignupModal = lazy(() => import('./LoginSignupModal'));

const LazyLoginSignUpModal = props => (
  <Suspense fallback={null}>
    <LoginSignupModal {...props} />
  </Suspense>
);

export default LazyLoginSignUpModal;
